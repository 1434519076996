import React from 'react';
import styled from 'styled-components';

const BackgroundBlur = styled.div`
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 0.5 : 0)};
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  z-index: 999;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(50px);
  overflow-y: hidden;
`;

const Disclaimer = styled.div`
  opacity: ${(props) => (props.show ? 1 : 0)};
  display: ${(props) => (props.show ? 'block' : 'none')};
  width: 700px;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px 25px 70px 25px;
  border-radius: 15px;
  z-index: 1000;
  transition: opacity 0.5s linear 1s;
  box-shadow: 0 0 10px 3px #8e8e8e;

  @media screen and (max-width: 775px) {
    width: 90%;
    height: auto;
  }

  .modalHeader {
    font-size: 24px;
    color: #28475a;
    text-align: left;
  }

  .modalBody {
    font-size: 16px;
    text-align: left;
    color: black;
  }

  .modalButton {
    position: absolute;
    bottom: 20px;
    padding: 10px 20px !important;
    font-size: 16px !important;
    border-radius: 10px;
    width: 115px;
    text-align: center;
    transition-duration: 0.3s;
    line-height: 1.5 !important;

    &.cancel {
      color: #262626;
      right: 150px;
      margin-bottom: 0;
      cursor: pointer;
      border: 1px solid #262626;
      display: inline;

      &:hover {
      }
    }

    &.continue {
      color: white !important;
      background-color: #28475a;
      border-radius: 10px;
      right: 25px;
      border: 2px solid #28475a;
      transition-duration: 0.3s;

      &:hover {
        color: #28475a !important;
        font-weight: bold;
        background-color: transparent;
      }
    }
  }
`;

const LinkDisclaimer = ({
  showDisclaimer,
  setShowDisclaimer,
  disclaimerLink
}) => {
  return (
    <>
      <BackgroundBlur show={showDisclaimer} />
      <Disclaimer show={showDisclaimer}>
        <p className='modalHeader'>Confirmation</p>
        <p className='modalBody'>
          The link you have selected will take you to a site outside of
          saferchildbirtcities.com. Merck does not review or control any
          non-Merck site. Merck does not endorse and is not responsible for the
          accuracy, content, practices or standards of any non-Merck site. Merck
          is known as MSD outside of the United States and Canada.
        </p>
        <p
          className='modalButton cancel'
          onClick={() => setShowDisclaimer(false)}
        >
          Cancel
        </p>
        <a
          className='modalButton continue'
          href={disclaimerLink}
          onClick={() => setShowDisclaimer(false)}
          target='_blank'
        >
          Continue
        </a>
      </Disclaimer>
    </>
  );
};

export default LinkDisclaimer;
